import type { HitCompactProps } from './HitCompact';
import type { TLinkedProduct, TProductES } from '@vitafy/common-schemas';
import type { THit } from '@vitafy/search-data-api-contracts';

export const reduceRelatedProducts = (product: TLinkedProduct): HitCompactProps => {
  return {
    id: product.entity_id.toString(),
    name: product.name,
    url: `${product.url_key}.html`,
    prices: product.prices,
    inStock: product.in_stock,
    reviews: {
      totalReviews: product.reviews_total,
      rating: product.reviews_rating
    },
    productOptions: product.product_options || null,
    type: product.product_options && product.product_options?.length ? 'bundle' : 'simple', // TODO fix when API includes type
    figure: {
      image: {
        path: `media/catalog/product/${product.base_image}`
      },
      alt: `${product.name} depicted`
    }
  };
};

export const reduceProductEs = (product: TProductES): HitCompactProps => {
  return {
    id: product.entity_id.toString(),
    name: product.name,
    url: product.url,
    prices: product.prices,
    inStock: product.in_stock,
    productOptions: product.product_options || null,
    reviews: {
      totalReviews: product.totalReviews,
      rating: product.averageRating
    },
    figure: {
      image: {
        path: product.image.path
      },
      alt: `${product.name} depicted`
    }
  };
};

export const reduceProductHit = (product: THit): HitCompactProps => {
  return {
    id: product.id.toString(),
    name: product.name,
    url: product.url,
    prices: product.prices,
    inStock: product.in_stock,
    productOptions: product.product_options || null,
    reviews: {
      totalReviews: product.totalReviews,
      rating: product.averageRating
    },
    figure: {
      image: {
        path: product.image.path
      },
      alt: `${product.name} depicted`
    }
  };
};
